<template>
  <form @keydown="form.onKeydown($event)" @submit.prevent="saveCarInspection">

    <div class="form-group">
      <div class="row">
        <div class="col-xl-6 col-md-12" v-bind:class="{ 'has-error': form.errors.has('wheels_condition') }">
          <h5 class="text-left font-medium mb-3">Состояние колес</h5>

          <div class="form-group">
            <div class="row">
              <div class="col-6"
                   v-bind:class="{ 'has-error': form.errors.has('wheels_condition.tire_condition') }">
                <label class="font-medium font-small">
                  Резина
                </label>
                <select class="form-select"
                        v-model="form.wheels_condition.tire_condition"
                        aria-label="Выберите состояние">
                  <option :value="null" selected>Выберите состояние</option>
                  <option v-for="(name, type) in condition_types" :value="name">
                    {{ name }}
                  </option>
                </select>
                <HasError :form="form" field="wheels_condition.tire_condition" />
              </div>
              <div class="col-6"
                   v-bind:class="{ 'has-error': form.errors.has('wheels_condition.tire_condition_comment') }">
                <label class="font-medium font-small">
                  Резина (комментарий)
                </label>
                <input class="form-control"
                       id="wheels_condition_tire_condition_comment"
                       name="wheels_condition_tire_condition_comment"
                       placeholder="Комментарий"
                       title="Комментарий"
                       type="text"
                       v-model="form.wheels_condition.tire_condition_comment">
                <HasError :form="form" field="wheels_condition.tire_condition_comment" />
              </div>
              <div class="col-6"
                   v-bind:class="{ 'has-error': form.errors.has('wheels_condition.rim_condition') }">
                <label class="font-medium font-small">
                  Диски
                </label>
                <select class="form-select"
                        v-model="form.wheels_condition.rim_condition"
                        aria-label="Выберите состояние">
                  <option :value="null" selected>Выберите состояние</option>
                  <option v-for="(name, type) in condition_types" :value="name">
                    {{ name }}
                  </option>
                </select>
                <HasError :form="form" field="wheels_condition.rim_condition" />
              </div>
              <div class="col-6"
                   v-bind:class="{ 'has-error': form.errors.has('wheels_condition.rim_condition') }">
                <label class="font-medium font-small">
                  Диски (комментарий)
                </label>
                <input class="form-control"
                       id="wheels_condition_rim_condition_comment"
                       name="wheels_condition_rim_condition_comment"
                       placeholder="Комментарий"
                       title="Комментарий"
                       type="text"
                       v-model="form.wheels_condition.rim_condition_comment">
                <HasError :form="form" field="wheels_condition.rim_condition" />
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-12"
                   v-bind:class="{ 'has-error': form.errors.has('additional_marking.tire_second_set') }">
                <input class="pointer"
                       id="additional_marking_tire_second_set"
                       style="transform: scale(1.2);"
                       type="checkbox"
                       v-model="form.additional_marking.tire_second_set">
                <label for="additional_marking_tire_second_set" class="margin-l-5">Второй комплект</label>
              </div>
            </div>
          </div>
          <HasError :form="form" field="wheels_condition" />
        </div>
        <div class="col-xl-6 col-md-12" v-bind:class="{ 'has-error': form.errors.has('computer_diagnostic') }">
          <h5 class="text-left font-medium mb-3">Компьютерная диагностика</h5>

          <div class="form-group">
            <div class="row">
              <div class="col-12"
                   v-bind:class="{ 'has-error': form.errors.has('computer_diagnostic.has_errors') }">
                <input class="pointer"
                       id="computer_diagnostic_has_errors"
                       style="transform: scale(1.2);"
                       type="checkbox"
                       v-model="form.computer_diagnostic.has_errors">
                <label for="computer_diagnostic_has_errors" class="margin-l-5">Есть ошибки</label>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-12"
                   v-bind:class="{ 'has-error': form.errors.has('computer_diagnostic.comment') }">
                <textarea class="form-control"
                          id="computer_diagnostic_comment"
                          name="computer_diagnostic_comment"
                          type="text"
                          placeholder="Комментарий"
                          v-model="form.computer_diagnostic.comment" rows="3"></textarea>
                <HasError :form="form" field="computer_diagnostic.comment" />
              </div>
            </div>
          </div>
          <HasError :form="form" field="computer_diagnostic" />
        </div>
      </div>
    </div>

    <hr />

    <div class="form-group">
      <div class="row">
        <div class="col-xl-6 col-md-12" v-bind:class="{ 'has-error': form.errors.has('interior_condition') }">
          <h5 class="text-left font-medium mb-3">Состояние салона</h5>

          <div class="row">

            <div class="col-xl-6 col-md-12"
                 v-bind:class="{ 'has-error': form.errors.has('interior_condition.wheel_and_gearbox') }">
              <label class="font-medium font-small">
                Руль и КПП
              </label>
              <label class="full-width" for="interior_condition_wheel_and_gearbox">
                <select class="form-select"
                        v-model="form.interior_condition.wheel_and_gearbox"
                        aria-label="Выберите состояние">
                  <option :value="null" selected>Выберите состояние</option>
                  <option v-for="(name, type) in condition_types" :value="name">
                    {{ name }}
                  </option>
                </select>
                <HasError :form="form" field="interior_condition.wheel_and_gearbox" />
              </label>
            </div>
            <div class="col-xl-6 col-md-12"
                 v-bind:class="{ 'has-error': form.errors.has('interior_condition.wheel_and_gearbox_comment') }">
              <label class="font-medium font-small">
                Руль и КПП комментарий
              </label>
              <label class="full-width" for="interior_condition_wheel_and_gearbox_comment">
                <input class="form-control"
                       id="interior_condition_wheel_and_gearbox_comment"
                       name="interior_condition_wheel_and_gearbox_comment"
                       placeholder="Комментарий"
                       title="Комментарий"
                       type="text"
                       v-model="form.interior_condition.wheel_and_gearbox_comment">
                <HasError :form="form" field="interior_condition.wheel_and_gearbox_comment" />
              </label>
            </div>
            <div class="col-xl-12 col-md-12"
                 v-bind:class="{ 'has-error': form.errors.has('interior_condition.equipment') }">
              <label class="font-medium font-small">
                Оборудование
              </label>
              <label class="full-width" for="interior_condition_equipment">
                <input class="form-control"
                       id="interior_condition_equipment"
                       name="interior_condition_equipment"
                       placeholder="Оборудование"
                       title="Оборудование"
                       type="text"
                       v-model="form.interior_condition.equipment">
                <HasError :form="form" field="interior_condition.equipment" />
              </label>
            </div>
            <div class="col-xl-6 col-md-12"
                 v-bind:class="{ 'has-error': form.errors.has('interior_condition.seats') }">
              <label class="font-medium font-small">
                Сиденья
              </label>
              <label class="full-width" for="interior_condition_seats">
                <select class="form-select"
                        v-model="form.interior_condition.seats"
                        aria-label="Выберите состояние">
                  <option :value="null" selected>Выберите состояние</option>
                  <option v-for="(name, type) in condition_types" :value="name">
                    {{ name }}
                  </option>
                </select>
                <HasError :form="form" field="interior_condition.seats" />
              </label>
            </div>
            <div class="col-xl-6 col-md-12"
                 v-bind:class="{ 'has-error': form.errors.has('interior_condition.seats_comment') }">
              <label class="font-medium font-small">
                Сиденья (комментарий)
              </label>
              <label class="full-width" for="interior_condition_seats_comment">
                <input class="form-control"
                       id="interior_condition_seats_comment"
                       name="interior_condition_seats_comment"
                       placeholder="Комментарий"
                       title="Комментарий"
                       type="text"
                       v-model="form.interior_condition.seats_comment">
                <HasError :form="form" field="interior_condition.seats_comment" />
              </label>
            </div>
            <div class="col-xl-6 col-md-12"
                 v-bind:class="{ 'has-error': form.errors.has('interior_condition.floor') }">
              <label class="font-medium font-small">
                Пол
              </label>
              <label class="full-width" for="interior_condition_floor">
                <select class="form-select"
                        v-model="form.interior_condition.floor"
                        aria-label="Выберите состояние">
                  <option :value="null" selected>Выберите состояние</option>
                  <option v-for="(name, type) in condition_types" :value="name">
                    {{ name }}
                  </option>
                </select>
                <HasError :form="form" field="interior_condition.floor" />
              </label>
            </div>
            <div class="col-xl-6 col-md-12"
                 v-bind:class="{ 'has-error': form.errors.has('interior_condition.floor_comment') }">
              <label class="font-medium font-small">
                Пол (комментарий)
              </label>
              <label class="full-width" for="interior_condition_floor_comment">
                <input class="form-control"
                       id="interior_condition_floor_comment"
                       name="interior_condition_floor_comment"
                       placeholder="Комментарий"
                       title="Комментарий"
                       type="text"
                       v-model="form.interior_condition.floor_comment">
                <HasError :form="form" field="interior_condition.floor_comment" />
              </label>
            </div>
            <div class="col-xl-6 col-md-12"
                 v-bind:class="{ 'has-error': form.errors.has('interior_condition.ceiling') }">
              <label class="font-medium font-small">
                Потолок
              </label>
              <label class="full-width" for="interior_condition_ceiling">
                <select class="form-select"
                        v-model="form.interior_condition.ceiling"
                        aria-label="Выберите состояние">
                  <option :value="null" selected>Выберите состояние</option>
                  <option v-for="(name, type) in condition_types" :value="name">
                    {{ name }}
                  </option>
                </select>
                <HasError :form="form" field="interior_condition.ceiling" />
              </label>
            </div>
            <div class="col-xl-6 col-md-12"
                 v-bind:class="{ 'has-error': form.errors.has('interior_condition.ceiling_comment') }">
              <label class="font-medium font-small">
                Потолок (комментарий)
              </label>
              <label class="full-width" for="interior_condition_ceiling_comment">
                <input class="form-control"
                       id="interior_condition_ceiling_comment"
                       name="interior_condition_ceiling_comment"
                       placeholder="Комментарий"
                       title="Комментарий"
                       type="text"
                       v-model="form.interior_condition.ceiling_comment">
                <HasError :form="form" field="interior_condition.ceiling_comment" />
              </label>
            </div>
            <div class="col-xl-6 col-md-12"
                 v-bind:class="{ 'has-error': form.errors.has('interior_condition.trunk') }">
              <label class="font-medium font-small">
                Багажник
              </label>
              <label class="full-width" for="interior_condition_trunk">
                <select class="form-select"
                        v-model="form.interior_condition.trunk"
                        aria-label="Выберите состояние">
                  <option :value="null" selected>Выберите состояние</option>
                  <option v-for="(name, type) in condition_types" :value="name">
                    {{ name }}
                  </option>
                </select>
                <HasError :form="form" field="interior_condition.trunk" />
              </label>
            </div>
            <div class="col-xl-6 col-md-12"
                 v-bind:class="{ 'has-error': form.errors.has('interior_condition.trunk_comment') }">
              <label class="font-medium font-small">
                Багажник (комментарий)
              </label>
              <label class="full-width" for="interior_condition_trunk_comment">
                <input class="form-control"
                       id="interior_condition_trunk_comment"
                       name="interior_condition_trunk_comment"
                       placeholder="Комментарий"
                       title="Комментарий"
                       type="text"
                       v-model="form.interior_condition.trunk_comment">
                <HasError :form="form" field="interior_condition.trunk_comment" />
              </label>
            </div>
          </div>

          <HasError :form="form" field="interior_condition" />
        </div>

        <div class="col-xl-6 col-md-12" v-bind:class="{ 'has-error': form.errors.has('additional_marking') }">
          <h5 class="text-left font-medium mb-3">Дополнительные отметки</h5>

          <div class="row">

            <div class="col-xl-6 col-md-12"
                 v-bind:class="{ 'has-error': form.errors.has('additional_marking.sales_market') }">
              <label class="font-medium font-small">
                Рынок сбыта
              </label>
              <label class="full-width" for="additional_marking_sales_market">
                <input class="form-control"
                       id="additional_marking_sales_market"
                       name="additional_marking_sales_market"
                       placeholder="Рынок сбыта"
                       title="Рынок сбыта"
                       type="text"
                       v-model="form.additional_marking.sales_market">
                <HasError :form="form" field="additional_marking.sales_market" />
              </label>
            </div>
            <div class="col-xl-6 col-md-12"
                 v-bind:class="{ 'has-error': form.errors.has('additional_marking.keys_count') }">
              <label class="font-medium font-small">
                Количество ключей
              </label>
              <label class="full-width" for="additional_marking_keys_count">
                <input class="form-control"
                       id="additional_marking_keys_count"
                       name="additional_marking_keys_count"
                       placeholder="Количество ключей"
                       title="Количество ключей"
                       type="number"
                       v-model="form.additional_marking.keys_count">
                <HasError :form="form" field="additional_marking.keys_count" />
              </label>
            </div>
            <div class="col-xl-6 col-md-12"
                 v-bind:class="{ 'has-error': form.errors.has('additional_marking.owners_count') }">
              <label class="font-medium font-small">
                Количество владельцев
              </label>
              <label class="full-width" for="additional_marking_owners_count">
                <input class="form-control"
                       id="additional_marking_owners_count"
                       name="additional_marking_owners_count"
                       placeholder="Количество владельцев"
                       title="Количество владельцев"
                       type="number"
                       v-model="form.additional_marking.owners_count">
                <HasError :form="form" field="additional_marking.owners_count" />
              </label>
            </div>
            <div class="col-xl-6 col-md-12"
                 v-bind:class="{ 'has-error': form.errors.has('additional_marking.has_service_book') }">
              <div class="mt-4 mb-4">
                <input class="pointer"
                       id="additional_marking_has_service_book"
                       style="transform: scale(1.2);"
                       type="checkbox"
                       v-model="form.additional_marking.has_service_book">
                <label for="additional_marking_has_service_book" class="margin-l-5">Сервисная книга</label>
              </div>
            </div>
            <div class="col-xl-6 col-md-12"
                 v-bind:class="{ 'has-error': form.errors.has('additional_marking.last_tech_inspect_race') }">
              <label class="font-medium font-small">
                Последний техосмотр, тыс. км.
              </label>
              <label class="full-width" for="additional_marking_last_tech_inspect_race">
                <input class="form-control"
                       id="additional_marking_last_tech_inspect_race"
                       name="additional_marking_last_tech_inspect_race"
                       placeholder="Последний техосмотр"
                       title="Последний техосмотр"
                       type="number"
                       v-model="form.additional_marking.last_tech_inspect_race">
                <HasError :form="form" field="additional_marking.last_tech_inspect_race" />
              </label>
            </div>
            <div class="col-xl-6 col-md-12"
                 v-bind:class="{ 'has-error': form.errors.has('additional_marking.last_tech_inspect_changes') }">
              <label class="font-medium font-small">
                Замена
              </label>
              <label class="full-width" for="additional_marking_last_tech_inspect_changes">
                <input class="form-control"
                       id="additional_marking_last_tech_inspect_changes"
                       name="additional_marking_last_tech_inspect_changes"
                       placeholder="Замена"
                       title="Замена"
                       type="text"
                       v-model="form.additional_marking.last_tech_inspect_changes">
                <HasError :form="form" field="additional_marking.last_tech_inspect_changes" />
              </label>
            </div>
            <div class="col-xl-6 col-md-12"
                 v-bind:class="{ 'has-error': form.errors.has('additional_marking.big_tech_inspect') }">
              <label class="font-medium font-small">
                Большой техосмотр
              </label>
              <label class="full-width" for="additional_marking_big_tech_inspect">
                <input class="form-control"
                       id="additional_marking_big_tech_inspect"
                       name="additional_marking_big_tech_inspect"
                       placeholder="Большой техосмотр"
                       title="Большой техосмотр"
                       type="text"
                       v-model="form.additional_marking.big_tech_inspect">
                <HasError :form="form" field="additional_marking.big_tech_inspect" />
              </label>
            </div>
            <div class="col-xl-6 col-md-12"
                 v-bind:class="{ 'has-error': form.errors.has('additional_marking.big_tech_inspect_changes') }">
              <label class="font-medium font-small">
                Замена
              </label>
              <label class="full-width" for="additional_marking_big_tech_inspect_changes">
                <input class="form-control"
                       id="additional_marking_big_tech_inspect_changes"
                       name="additional_marking_big_tech_inspect_changes"
                       placeholder="Замена"
                       title="Замена"
                       type="text"
                       v-model="form.additional_marking.big_tech_inspect_changes">
                <HasError :form="form" field="additional_marking.big_tech_inspect_changes" />
              </label>
            </div>
            <div class="col-xl-6 col-md-12"
                 v-bind:class="{ 'has-error': form.errors.has('additional_marking.need_changes') }">
              <label class="font-medium font-small">
                Требует замены и рекомендации
              </label>
              <label class="full-width" for="additional_marking_need_changes">
                  <textarea class="form-control"
                            id="additional_marking_need_changes"
                            rows="2"
                            v-model="form.additional_marking.need_changes">
                  </textarea>
                <HasError :form="form" field="additional_marking.need_changes" />
              </label>
            </div>
            <div class="col-xl-6 col-md-12"
                 v-bind:class="{ 'has-error': form.errors.has('additional_marking.notes') }">
              <label class="font-medium font-small">
                Примечания
              </label>
              <label class="full-width" for="additional_marking_notes">
                  <textarea class="form-control"
                            id="additional_marking_notes"
                            rows="3"
                            v-model="form.additional_marking.notes">
                  </textarea>
                <HasError :form="form" field="additional_marking.notes" />
              </label>
            </div>
          </div>

          <HasError :form="form" field="additional_marking" />
        </div>
      </div>
    </div>

    <hr />

    <div class="form-group">
      <div class="row">
        <div class="col-12 text-center" v-bind:class="{ 'has-error': form.errors.has('paintwork_details') }">
          <h5 class="text-left font-medium mb-3">Состояние ЛКП</h5>

          <div class="row">
            <div class="col-xl-6 col-md-12">
              <div class="image-box">
                <img src="../../../../assets/img/inspection_scheme/car_scheme_front.png" class="img-fluid" alt="">
                <button class="btn btn-sm" id="front_bumper" type="button"
                        :class="form.paintwork_details['front']['bumper'].condition_type ? 'btn-warning' : 'btn-primary'"
                        v-on:click="showPaintworkDetailsModal('front', 'bumper')">
                  <i class="fas fa-map-marker"></i>
                </button>
                <button class="btn btn-sm" id="front_hood" type="button"
                        :class="form.paintwork_details['front']['hood'].condition_type ? 'btn-warning' : 'btn-primary'"
                        v-on:click="showPaintworkDetailsModal('front', 'hood')">
                  <i class="fas fa-map-marker"></i>
                </button>
              </div>
            </div>
            <div class="col-xl-6 col-md-12">
              <div class="image-box">
                <img src="../../../../assets/img/inspection_scheme/car_scheme_side_left.png" class="img-fluid" alt=""
                     style="width: 90%;">
                <button class="btn btn-sm" id="side_left_front_wing" type="button"
                        :class="form.paintwork_details['side_left']['front_wing'].condition_type ? 'btn-warning' : 'btn-primary'"
                        v-on:click="showPaintworkDetailsModal('side_left', 'front_wing')">
                  <i class="fas fa-map-marker"></i>
                </button>
                <button class="btn btn-sm" id="side_left_front_door" type="button"
                        :class="form.paintwork_details['side_left']['front_door'].condition_type ? 'btn-warning' : 'btn-primary'"
                        v-on:click="showPaintworkDetailsModal('side_left', 'front_door')">
                  <i class="fas fa-map-marker"></i>
                </button>
                <button class="btn btn-sm" id="side_left_back_door" type="button"
                        :class="form.paintwork_details['side_left']['back_door'].condition_type ? 'btn-warning' : 'btn-primary'"
                        v-on:click="showPaintworkDetailsModal('side_left', 'back_door')">
                  <i class="fas fa-map-marker"></i>
                </button>
                <button class="btn btn-sm" id="side_left_back_wing" type="button"
                        :class="form.paintwork_details['side_left']['back_wing'].condition_type ? 'btn-warning' : 'btn-primary'"
                        v-on:click="showPaintworkDetailsModal('side_left', 'back_wing')">
                  <i class="fas fa-map-marker"></i>
                </button>
              </div>
            </div>
            <div class="col-xl-6 col-md-12">
              <div class="image-box">
                <img src="../../../../assets/img/inspection_scheme/car_scheme_side_right.png" class="img-fluid" alt=""
                     style="width: 90%;">
                <button class="btn btn-sm" id="side_right_front_wing" type="button"
                        :class="form.paintwork_details['side_right']['front_wing'].condition_type ? 'btn-warning' : 'btn-primary'"
                        v-on:click="showPaintworkDetailsModal('side_right', 'front_wing')">
                  <i class="fas fa-map-marker"></i>
                </button>
                <button class="btn btn-sm" id="side_right_front_door" type="button"
                        :class="form.paintwork_details['side_right']['front_door'].condition_type ? 'btn-warning' : 'btn-primary'"
                        v-on:click="showPaintworkDetailsModal('side_right', 'front_door')">
                  <i class="fas fa-map-marker"></i>
                </button>
                <button class="btn btn-sm" id="side_right_back_door" type="button"
                        :class="form.paintwork_details['side_right']['back_door'].condition_type ? 'btn-warning' : 'btn-primary'"
                        v-on:click="showPaintworkDetailsModal('side_right', 'back_door')">
                  <i class="fas fa-map-marker"></i>
                </button>
                <button class="btn btn-sm" id="side_right_back_wing" type="button"
                        :class="form.paintwork_details['side_right']['back_wing'].condition_type ? 'btn-warning' : 'btn-primary'"
                        v-on:click="showPaintworkDetailsModal('side_right', 'back_wing')">
                  <i class="fas fa-map-marker"></i>
                </button>
              </div>
            </div>
            <div class="col-xl-6 col-md-12">
              <div class="image-box">
                <img src="../../../../assets/img/inspection_scheme/car_scheme_back.png" class="img-fluid" alt="">
                <button class="btn btn-sm" id="back_bumper" type="button"
                        :class="form.paintwork_details['back']['bumper'].condition_type ? 'btn-warning' : 'btn-primary'"
                        v-on:click="showPaintworkDetailsModal('back', 'bumper')">
                  <i class="fas fa-map-marker"></i>
                </button>
                <button class="btn btn-sm" id="back_tailgate_bottom" type="button"
                        :class="form.paintwork_details['back']['tailgate_bottom'].condition_type ? 'btn-warning' : 'btn-primary'"
                        v-on:click="showPaintworkDetailsModal('back', 'tailgate_bottom')">
                  <i class="fas fa-map-marker"></i>
                </button>
              </div>
            </div>
            <div class="col-xl-6 col-md-12">
              <div class="image-box">
                <img src="../../../../assets/img/inspection_scheme/car_scheme_roof.png" class="img-fluid" alt=""
                     style="width: 90%;">
                <button class="btn btn-sm" id="roof_tailgate_top" type="button"
                        :class="form.paintwork_details['roof']['tailgate_top'].condition_type ? 'btn-warning' : 'btn-primary'"
                        v-on:click="showPaintworkDetailsModal('roof', 'tailgate_top')">
                  <i class="fas fa-map-marker"></i>
                </button>
                <button class="btn btn-sm" id="roof_main" type="button"
                        :class="form.paintwork_details['roof']['main'].condition_type ? 'btn-warning' : 'btn-primary'"
                        v-on:click="showPaintworkDetailsModal('roof', 'main')">
                  <i class="fas fa-map-marker"></i>
                </button>
              </div>
            </div>
          </div>

          <modal :adaptive="true"
                 :clickToClose="true"
                 :draggable="false"
                 name="paintwork_details_modal"
                 :resizable="false"
                 :scrollable="false"
                 :shiftX="0.5"
                 :shiftY="0.5"
                 :height="'auto'"
                 overlayTransition="fade"
                 transition="fade">
            <div class="box box-primary box-modal mb-0"
                 v-if="paintwork_modal_data.section && paintwork_modal_data.detail_type">
              <div class="box-header with-border mb-3">
                <h4>
                  {{ paintwork_modal_data.section.toUpperCase() }}
                  {{ paintwork_modal_data.detail_type.toUpperCase() }}
                </h4>
              </div>

              <div class="box-body">
                <input class="form-control hidden"
                       style="display: none;"
                       name="paintwork_modal_data_section"
                       type="text"
                       v-model="paintwork_modal_data.section">
                <input class="form-control hidden"
                       style="display: none;"
                       name="paintwork_modal_data_detail_type"
                       type="text"
                       v-model="paintwork_modal_data.detail_type">

                <div class="form-group">
                  <div class="row">
                    <div class="col-6">
                      <select class="form-select"
                              v-model="paintwork_modal_data.condition_type"
                              aria-label="Выберите состояние">
                        <option :value="null" selected>Выберите состояние</option>
                        <option v-for="(name, type) in paintwork_condition_types" :value="name">
                          {{ name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="form-group"
                     v-if="paintwork_modal_data.condition_type === paintwork_condition_types.painted">
                  <div class="row">
                    <div class="col-6">
                      <label for="paintwork_modal_data_from_mcr">
                        От, мкр.
                      </label>
                      <input class="form-control"
                             id="paintwork_modal_data_from_mcr"
                             name="paintwork_modal_data_from_mcr"
                             placeholder="От, мкр."
                             type="number"
                             min="0"
                             v-model="paintwork_modal_data.from_mcr">
                    </div>
                    <div class="col-6">
                      <label for="paintwork_modal_data_to_mcr">
                        До, мкр.
                      </label>
                      <input class="form-control"
                             id="paintwork_modal_data_to_mcr"
                             name="paintwork_modal_data_to_mcr"
                             placeholder="До, мкр."
                             type="number"
                             min="0"
                             v-model="paintwork_modal_data.to_mcr">
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <div class="row">
                    <div class="col-12">
                      <label for="paintwork_modal_data_additional_mark">
                        <input class="form-control"
                               id="paintwork_modal_data_additional_mark"
                               name="paintwork_modal_data_additional_mark"
                               placeholder="Примечание"
                               type="text"
                               v-model="paintwork_modal_data.additional_mark">
                      </label>
                    </div>
                  </div>
                </div>

                <button class="btn btn-success btn-lg pull-right margin-l-10" type="button"
                        v-on:click="addPaintworkDetail">
                  <i class="fa fa-save"></i>
                </button>

                <button class="btn btn-default btn-lg pull-right" title="Отменить и закрыть окно" type="button"
                        v-on:click="hidePaintworkDetailsModal">
                  <i class="far fa-window-close"></i>
                </button>
              </div>
            </div>
          </modal>

          <HasError :form="form" field="paintwork_details" />
        </div>
      </div>
    </div>

    <br />
    <hr />

    <AlertErrors :form="form" message="Ошибки валидации. Проверьте введённые данные." />

    <Button :disabled="!validateData || form.busy"
            :form="form"
            :loading="form.busy"
            class="btn btn-lg btn-success pull-right"
            style="min-width: 100px;">
      <i class="fa fa-save"></i>
    </Button>

  </form>
</template>

<script>
import Vue from "vue";
import VModal from "vue-js-modal";
import Form from "vform";
import Multiselect from "vue-multiselect";

Vue.use(VModal);

Vue.component("car-inspection-item", (resolve) => {
  return require(["./item"], resolve);
});

Form.axios = API.apiClient;

export default {
  name: "create-car-inspection",

  components: {
    Button, HasError, AlertError, AlertErrors,
    Multiselect,
  },

  props: {
    carId: {
      type: [String, Number],
      required: true,
    },
  },

  data: () => ({
    form: new Form({
      wheels_condition: {
        tire_condition: null,
        tire_condition_comment: null,
        rim_condition: null,
        rim_condition_comment: null,
      },
      computer_diagnostic: {
        has_errors: false,
        comment: null,
      },
      interior_condition: {
        wheel_and_gearbox: null,
        wheel_and_gearbox_comment: null,
        equipment: null,
        seats: null,
        seats_comment: null,
        floor: null,
        floor_comment: null,
        ceiling: null,
        ceiling_comment: null,
        trunk: null,
        trunk_comment: null,
      },
      additional_marking: {
        tire_second_set: false,
        sales_market: null,
        keys_count: null,
        owners_count: null,
        has_service_book: false,
        last_tech_inspect_race: null,
        last_tech_inspect_changes: null,
        big_tech_inspect: null,
        big_tech_inspect_changes: null,
        need_changes: null,
        notes: null,
        wheels_condition: null,
        computer_diagnostic: null,
      },
      paintwork_details: {
        front: {
          bumper: {
            condition_type: null,
            from_mcr: null,
            to_mcr: null,
            additional_mark: null,
          },
          hood: {
            condition_type: null,
            from_mcr: null,
            to_mcr: null,
            additional_mark: null,
          },
        },
        side_left: {
          front_wing: {
            condition_type: null,
            from_mcr: null,
            to_mcr: null,
            additional_mark: null,
          },
          front_door: {
            condition_type: null,
            from_mcr: null,
            to_mcr: null,
            additional_mark: null,
          },
          back_door: {
            condition_type: null,
            from_mcr: null,
            to_mcr: null,
            additional_mark: null,
          },
          back_wing: {
            condition_type: null,
            from_mcr: null,
            to_mcr: null,
            additional_mark: null,
          },
        },
        side_right: {
          front_wing: {
            condition_type: null,
            from_mcr: null,
            to_mcr: null,
            additional_mark: null,
          },
          front_door: {
            condition_type: null,
            from_mcr: null,
            to_mcr: null,
            additional_mark: null,
          },
          back_door: {
            condition_type: null,
            from_mcr: null,
            to_mcr: null,
            additional_mark: null,
          },
          back_wing: {
            condition_type: null,
            from_mcr: null,
            to_mcr: null,
            additional_mark: null,
          },
        },
        back: {
          bumper: {
            condition_type: null,
            from_mcr: null,
            to_mcr: null,
            additional_mark: null,
          },
          tailgate_bottom: {
            condition_type: null,
            from_mcr: null,
            to_mcr: null,
            additional_mark: null,
          },
        },
        roof: {
          tailgate_top: {
            condition_type: null,
            from_mcr: null,
            to_mcr: null,
            additional_mark: null,
          },
          main: {
            condition_type: null,
            from_mcr: null,
            to_mcr: null,
            additional_mark: null,
          },
        },
      },
    }),

    paintwork_modal_data: {
      condition_type: null,
      from_mcr: null,
      to_mcr: null,
      additional_mark: null,
      section: null,
      detail_type: null,
    },

    condition_types: {
      new: "Нове",
      good: "Гарне",
      medium: "Норм",
      poor: "Потребує ремонту",
    },

    paintwork_condition_types: {
      replace: "Заміна",
      painted: "Фарбовано",
      replace_in_color: "Заміна у колір",
    },
  }),

  computed: {
    validateData: function() {
      return this.form.wheels_condition && this.form.computer_diagnostic && this.form.interior_condition &&
        this.form.additional_marking && this.form.paintwork_details;
    },
  },

  methods: {
    async showPaintworkDetailsModal(section, detail_type) {
      if (this.form.paintwork_details[section][detail_type]) {
        this.paintwork_modal_data.section = section;
        this.paintwork_modal_data.detail_type = detail_type;

        this.paintwork_modal_data.condition_type = this.form.paintwork_details[section][detail_type].condition_type;
        this.paintwork_modal_data.from_mcr = this.form.paintwork_details[section][detail_type].from_mcr;
        this.paintwork_modal_data.to_mcr = this.form.paintwork_details[section][detail_type].to_mcr;
        this.paintwork_modal_data.additional_mark = this.form.paintwork_details[section][detail_type].additional_mark;

        this.$modal.show("paintwork_details_modal");
      }
    },
    async hidePaintworkDetailsModal() {
      this.paintwork_modal_data.section = null;
      this.paintwork_modal_data.detail_type = null;
      this.paintwork_modal_data.condition_type = null;
      this.paintwork_modal_data.from_mcr = null;
      this.paintwork_modal_data.to_mcr = null;
      this.paintwork_modal_data.additional_mark = null;

      this.$modal.hide("paintwork_details_modal");
    },
    addPaintworkDetail() {
      let modalData = this.paintwork_modal_data;

      this.form.paintwork_details[modalData.section][modalData.detail_type].condition_type = modalData.condition_type;
      this.form.paintwork_details[modalData.section][modalData.detail_type].from_mcr = modalData.from_mcr;
      this.form.paintwork_details[modalData.section][modalData.detail_type].to_mcr = modalData.to_mcr;
      this.form.paintwork_details[modalData.section][modalData.detail_type].additional_mark = modalData.additional_mark;

      this.hidePaintworkDetailsModal();
    },
    async saveCarInspection() {
      await this.form.post("/car/" + this.carId + "/inspections/add").then(() => {
        showSuccess();
        this.show_create_form = false;
      }).catch((error) => {
        errorHandler(error);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
table tr > td {
  padding: 5px;
}

.image-box {
  position: relative;

  .btn {
    position: absolute;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    opacity: 0.8;

    &#front_bumper {
      top: 65%;
      left: 50%;
    }

    &#front_hood {
      top: 38%;
      left: 60%;
    }

    &#side_left_front_wing {
      top: 42%;
      left: 22%;
    }

    &#side_left_front_door {
      top: 58%;
      left: 42%;
    }

    &#side_left_back_door {
      top: 55%;
      left: 65%;
    }

    &#side_left_back_wing {
      top: 42%;
      left: 82%;
    }

    &#side_right_front_wing {
      top: 42%;
      left: 78%;
    }

    &#side_right_front_door {
      top: 55%;
      left: 58%;
    }

    &#side_right_back_door {
      top: 58%;
      left: 36%;
    }

    &#side_right_back_wing {
      top: 40%;
      left: 19%;
    }

    &#back_bumper {
      top: 62%;
      left: 50%;
    }

    &#back_tailgate_bottom {
      top: 44%;
      left: 42%;
    }

    &#roof_tailgate_top {
      top: 45%;
      left: 88%;
    }

    &#roof_main {
      top: 50%;
      left: 55%;
    }
  }
}

.image-box img {
  height: auto;
  width: 40%;
  @media (max-width: 1024px) {
    width: 100%;
  }
}

tr > td {
  padding: 0;
}
</style>
