<template>
  <div class="box-body">

    <template
        v-if="([1, 7, 8, 9].includes($store.getters['auth/authUser'].role.id) || carResponsibleId === $store.getters['auth/authUser'].id) && !isDeleted">
      <button class="btn btn-lg pull-right mb-3"
              v-bind:class="show_create_form ? 'btn-default' : 'btn-success'"
              title="Добавить" type="button"
              v-on:click="toggleCreateForm">
        <i class="fa" v-bind:class="show_create_form ? 'fa-minus' : 'fa-plus'"></i>
      </button>

      <transition name="fade">
        <div class="collapse mt-5 mb-3" v-bind:class="show_create_form ? 'in' : ''"
             v-show="show_create_form" v-show-slide="show_create_form">
          <hr/>

          <h4 class="font-medium">
            Создать акт осмотра
          </h4>

          <hr/>

          <create-inspection-item :car-id="carId"/>
        </div>
      </transition>
    </template>

    <table-items v-show="!show_create_form"
                 :data_search="data_search"
                 :relations="relations"
                 component="car-inspection-item"
                 :columns="columns"
                 :api_resource="'/car/' + carId + '/inspections'">
    </table-items>
  </div>
</template>

<script>
import Vue from 'vue';
import TableItems from './../../../../components/pagination/table-items';
import CreateInspectionItem from './create';

Vue.component('car-inspection-item', (resolve) => {
  return require(['./item'], resolve);
});

export default {
  name: 'car-inspections',

  components: {
    CreateInspectionItem,
    TableItems,
  },

  props: {
    carId: {
      type: [String, Number],
      required: true,
    },
    carResponsibleId: {
      type: [String, Number],
      required: true,
    },
    isDeleted: {
      type: Boolean,
      required: true,
    },
    data_search: {
      type: String,
      required: false
    }
  },

  data: () => ({
    columns: [
      {
        title: 'ID',
        class: null,
        style: null,
      },
      {
        title: null,
        class: null,
        style: null,
      },
      {
        title: 'Создал',
        class: null,
        style: null,
      },
      {
        title: 'Дата',
        class: null,
        style: null,
      },
      {
        title: null,
        class: null,
        style: null,
      },
      {
        title: null,
        class: null,
        style: null,
      },
      {
        title: null,
        class: null,
        style: null,
      },
    ],
    relations: [
      'creator',
      'car.creator',
    ],

    show_create_form: false,
  }),

  methods: {
    async toggleCreateForm() {
      this.show_create_form = this.show_create_form === false;
    },
  },
}
</script>

<style lang="scss" scoped>
.collapse.in {
  overflow: unset !important;
}
</style>
